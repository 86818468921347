import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiInterceptors from '../../api/apiInterceptors';
const initialState = {
  loading: false,
  error: null,
};

export const uploadFile = createAsyncThunk(
  'uploadFile',
  async (formData, thunkAPI) => {
    try {
      const response = await apiInterceptors.post(
        'webchat/load_file/',
        formData,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      const { ticketId, message, userId } = action.payload;
      if (!state[ticketId]) state[ticketId] = []; // Если   сонмассеивтобщенийсоздаём —
      state[ticketId].push({ userId, message });
    },
    deleteChat: (state, action) => {
      const { ticketId } = action.payload;
      delete state[ticketId];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(uploadFile.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { addMessage, deleteChat } = chatSlice.actions;
export default chatSlice.reducer;
