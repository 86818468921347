import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiInterceptors from '../../../api/apiInterceptors';

export const updateTaskStatus = createAsyncThunk(
  'tasks/updateTaskStatus',
  async (ticketId, thunkAPI) => {
    try {
      const response = await apiInterceptors.post(
        `accounts/ticket_accept_or_decline/${ticketId}/`,
        { status: 'accepted' },
      );
      return { ticketId, updatedTask: response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const updateTaskSlice = createSlice({
  name: 'updateTask',
  initialState: { loading: false, error: null },
  extraReducers: builder => {
    builder
      .addCase(updateTaskStatus.pending, state => {
        state.loading = true;
      })
      .addCase(updateTaskStatus.fulfilled, state => {
        state.loading = false;
      })
      .addCase(updateTaskStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default updateTaskSlice.reducer;
