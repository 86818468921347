import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './Board.module.scss';
import Tasks from '../../../../constants/Tasks/Tasks';
import BtnProfile from '../../BtnProfile/BtnProfile';
import ModalDashBoard from '../../../../Components/DachBoard/modalWindow/ModalDashBoard';
import DataVerification from './DataVerification/DataVerification';
import ReportBug from './ReportBug/ReportBug';
import Search from '../../../../Components/DachBoard/Board/icon/Search';
import { useSelector } from 'react-redux';

const checkboxInput = [
  { id: 1, name: 'All' },
  { id: 2, name: 'At work' },
  { id: 3, name: 'Waiting' },
  { id: 4, name: 'Completed' },
];
const Board = memo(() => {
  const [filter, setFilter] = useState('All');
  const [checkingisOpen, setCheckingIsOpen] = useState(false);
  const [bugisOpen, setBugIsOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const tasksStore = useSelector(
    state => state.taskReducers.getTaskSlice.tasks,
  );
  useEffect(() => {
    if (checkingisOpen || bugisOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [checkingisOpen, bugisOpen]);

  const closeBugModal = () => setBugIsOpen(false);
  const closeCheckingModal = () => setCheckingIsOpen(false);
  const CloseCheckingModalAndOpenBugModal = () => {
    setCheckingIsOpen(false);
    setBugIsOpen(true);
  };
  const openCheckingModal = useCallback(
    ({ taskId, ticketId }) => {
      setSelectedTicketId(ticketId);
      const task = tasksStore.find(task => task.id === taskId);
      if (task) {
        setSelectedTask(task);
        setCheckingIsOpen(true);
      }
    },
    [tasksStore, setSelectedTask, setCheckingIsOpen], // добавьте зависимости, если необходимо
  );

  const handleCheckboxClick = name => {
    setFilter(name);
  };
  const filteredTasks = useMemo(() => {
    return tasksStore.filter(task => {
      switch (filter) {
        case 'All':
          return true;
        case 'At work':
          return task.status === 'At work';
        case 'Waiting':
          return task.status === 'Waiting';
        case 'Completed':
          return task.status === 'Completed';
        default:
          return true;
      }
    });
  }, [tasksStore, filter]);

  return (
    <div className={styles.main}>
      <div className={styles.containerTitleSearch}>
        <h1 className={styles.title}>Boards with projects</h1>
        <div className={styles.wrapperSearch}>
          <input className={styles.search} type="text" placeholder="Search" />
          <div className={styles.searchIcon}>
            <Search />
          </div>
        </div>
      </div>
      <div className={styles.borderBottom}></div>
      <ul className={styles.containerInputs}>
        {checkboxInput.map((item, index) => (
          <li key={index} className={styles.cardCheckbox}>
            <div className={styles.wrapperNameCheckbox}>
              <input
                type="checkbox"
                checked={filter === item.name}
                onChange={() => handleCheckboxClick(item.name)} // Сбрасываем фильтр
              />
              <span className={styles.nameCheckbox}>{item.name}</span>
            </div>
          </li>
        ))}
      </ul>
      <div className={styles.wrapperTasks}>
        {filteredTasks.map((task, index) => (
          <Tasks
            customHeight="220px"
            key={index}
            tasksStore={task}
            closeCheckingModal={closeCheckingModal}
            checkingisOpen={checkingisOpen}
            openCheckingModal={() =>
              openCheckingModal({ taskId: task.id, ticketId: task.ticket_id })
            }
            pathTo={`finishedWorks/${task.ticket_id}`}
          >
            <BtnProfile title="CHECK DATA" />
          </Tasks>
        ))}
      </div>
      <ModalDashBoard
        closeCheckingModal={closeCheckingModal}
        isOpen={checkingisOpen}
        openCheckingModal={closeCheckingModal}
      >
        <DataVerification
          selectedTask={selectedTask}
          onCloseByFocusLost={closeCheckingModal}
          onClose={CloseCheckingModalAndOpenBugModal}
        />
      </ModalDashBoard>

      <ModalDashBoard isOpen={bugisOpen}>
        <ReportBug
          ticketId={selectedTicketId}
          isOpen={bugisOpen}
          closeBugModal={closeBugModal}
        />
      </ModalDashBoard>
    </div>
  );
});

export default Board;
