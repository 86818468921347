import CloseIcon from '@mui/icons-material/Close';
import styles from './ReportBug.module.scss';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { addMessage } from '../../../../../redux/slices/chatSlice';
const ReportBug = ({ closeBugModal, isOpen, ticketId }) => {
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState('');

  const dispatch = useDispatch();
  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      closeBugModal();
    }
  };
  useEffect(() => {
    if (isOpen) {
      const token = Cookies.get('token');
      // const wsUrl = `ws://127.0.0.1:8000/ws/chat/?ticketId=${ticketId}&authorization=Bearer ${token}`;
      const wsUrl = `ws://aixland.io/ws/chat/?ticketId=${ticketId}&authorization=Bearer ${token}`;

      const ws = new WebSocket(wsUrl);

      ws.onmessage = event => {
        if (event.data.startsWith('{') && event.data.endsWith('}')) {
          try {
            const data = JSON.parse(event.data);
            if (data.type === 'message') {
              dispatch(
                addMessage({
                  ticketId,
                  message: data.message,
                  userId: data.user_id,
                }),
              );
            }
          } catch (error) {
            console.error('❌ JSON parsing error:', error);
          }
        }
      };
      ws.onerror = error => {
        console.error('⚠️ Error WebSocket:', error);
      };
      setSocket(ws);
      return () => {
        ws.close();
      };
    }
  }, [dispatch, ticketId, isOpen]);
  const handleSend = () => {
    if (socket && message.trim()) {
      const messageData = { message, type: 'send' };
      socket.send(JSON.stringify(messageData));
      setMessage('');
    }
  };
  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div className={styles.modalContainer}>
        <div onClick={closeBugModal} className={styles.backLink}>
          <CloseIcon />
        </div>
        <h1 className={styles.titleModal}>Message</h1>
        <div className={styles.mainAddFile}>
          <div className={styles.addFile}>Add a file</div>
          <div className={styles.plus}>+</div>
        </div>
        <div className={styles.mainInput}>
          <input
            placeholder="Comment..."
            className={styles.inputComment}
            type="text"
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
          <ArrowForwardIosIcon
            onClick={handleSend}
            className={styles.arrowForward}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportBug;
