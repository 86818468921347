import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './ModalFinishedWorks.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BtnProfile from '../../../BtnProfile/BtnProfile';
import SelectEngineer from '../SelectEngineer/SelectEngineer';
import SelectStatus from '../SelectStatus/SelectStatus';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../../../../redux/slices/usersSlice';
import { changeTaskStatus } from '../../../../../redux/slices/taskSlice/changeTaskStatus';
import { getTasks } from '../../../../../redux/slices/taskSlice/getTaskSlice';
import {
  addComment,
  deleteComment,
} from '../../../../../redux/slices/commentSlice';
import { getTask } from '../../../../../redux/slices/taskWorkerSLice';

const ModalFinishedWorks = React.memo(({ onClose, taskId }) => {
  const [activeTab, setActiveTab] = useState('Comments');
  const [comment, setComment] = useState('');
  const [сommentId, setCommentId] = useState(null);
  const users = useSelector(state => state.usersSlice.users);
  const user = useSelector(state => state.profileSlice.user);
  const taskItem = useSelector(state => state.taskWorkerSLice.task);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const toggleTab = useCallback(tab => {
    setActiveTab(tab);
  }, []);

  const handleChangeStatus = useCallback(
    newStatus => {
      if (taskItem) {
        dispatch(
          changeTaskStatus({ ticketId: taskItem.ticket_id, newStatus }),
        ).then(() => {
          dispatch(getTasks());
        });
      }
    },
    [dispatch, taskItem],
  );

  const handleChange = event => {
    const value = event.target.value;
    setComment(value);
  };

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      if (comment.trim()) {
        dispatch(addComment({ ticketId: taskItem.ticket_id, comment }))
          .then(() => {
            dispatch(getTask({ ticketId: taskId }));
            setComment('');

            // onClose(); // Закрыть модальное окно (если нужно)
          })
          .catch(error => {
            console.error('Error adding a comment:', error);
          });
      }
    },
    [comment, dispatch, taskId],
  );
  const handleDelete = commentId => {
    const confirmed = window.confirm('Are you sure you want to delete?');
    console.log(commentId);
    if (confirmed) {
      setCommentId(commentId);
      dispatch(deleteComment({ ticketId: taskId, id: commentId })).then(() => {
        dispatch(getTask({ ticketId: taskId }));
      });
      console.log('Deleted!');
    } else {
      console.log('Deletion cancelled.');
    }
  };

  const comments = taskItem.comments;
  const commentId = comments?.map(item => item.id);
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContainer}>
        <div onClick={onClose} className={styles.backLink}>
          <CloseIcon />
        </div>
        <h1 className={styles.titleModal}>
          Neuer Wall 10, 20354 Hamburg, Germany
        </h1>
        <div className={styles.border}></div>
        <div className={styles.wrapperContentComment}>
          <div className={styles.contentComment}>
            <p className={styles.userDescription}>Description</p>
            <p className={styles.description}>
              I'm sorry to hear about your pain, but i'm so sorry to hear about
              your pain and fatigue. As far as i'm concerned, any of our routine
              bears any effort to get something out of it is a convenient
              recipe.
            </p>
            <div className={styles.contentIconAdd}>
              <div className={styles.addTitle}>Add file</div>
              <AddIcon className={styles.iconAdd} />
            </div>
            <div className={styles.containerBtn}>
              <button
                className={
                  activeTab === 'Comments'
                    ? styles.activeButton
                    : styles.notActive
                }
                onClick={() => toggleTab('Comments')}
              >
                Comments
              </button>
              <button
                className={` ${styles.btnChronology} ${activeTab === 'Chronology' ? styles.activeButton : styles.notActive}`}
                onClick={() => toggleTab('Chronology')}
              >
                Chronology
              </button>
            </div>
            {activeTab === 'Chronology' ? (
              <div className={styles.chronologyMain}>
                <div className={styles.containerDataChronology}>
                  <div className={styles.avatarChronology}></div>
                  <div className={styles.userNameChronology}>
                    {user?.username}
                  </div>
                  <div className={styles.timeChronology}>
                    / 10.09.2024 / 14:25
                  </div>
                </div>
                <div className={styles.cardBehavior}>Created a card</div>
              </div>
            ) : (
              <div className={styles.containerInput}>
                <input
                  placeholder="Comment..."
                  className={styles.inputComment}
                  type="text"
                  value={comment}
                  onChange={handleChange}
                />

                <div className={styles.commentWrapper}>
                  <div>
                    {taskItem?.comments.map((comment, i) => (
                      <div key={i} className={styles.commentBlock}>
                        <div className={styles.mainWorkerComment}>
                          <div className={styles.workerComment}>
                            {comment.user}
                          </div>
                          /
                          <div className={styles.commentCreated_at}>
                            {comment.created_at}
                          </div>
                        </div>
                        <p className={styles.userComment}>{comment.comment}</p>
                        <button
                          onClick={() => handleDelete(comment.id)}
                          className={styles.deleteComment}
                        >
                          Delete
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <form
                  className={styles.handleSubmitComment}
                  onSubmit={handleSubmit}
                >
                  <button type="submit">
                    <ArrowForwardIosIcon className={styles.arrowIcon} />
                  </button>
                </form>
              </div>
            )}
          </div>
          <aside className={styles.aside}>
            <SelectStatus
              handleChangeStatus={handleChangeStatus}
              currentTask={taskItem}
            />
            <div className={styles.orderContentMb}>
              <SelectEngineer users={users} taskId={taskId} />
            </div>

            <div
              onClick={onClose}
              className={`${styles.wrapperBtn} ${activeTab === 'Chronology' ? styles.marginBtnAside : ''}`}
            >
              <BtnProfile padding="15px 0" title="THE TASK IS READY" />
            </div>
          </aside>
        </div>
      </div>
    </div>
  );
});

export default ModalFinishedWorks;
