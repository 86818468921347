import React, { useState } from 'react';
import styles from './ModalDigitalTwin.module.scss';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Chat = ({ handleMessage, inputRef, setMessage, message }) => {
  return (
    <div className={styles.containerInput}>
      <input
        ref={inputRef}
        placeholder="Comment..."
        className={styles.inputComment}
        type="text"
        value={message}
        onChange={e => setMessage(e.target.value)}
      />
      <ArrowForwardIosIcon
        className={styles.arrowIcon}
        onClick={handleMessage}
      />
    </div>
  );
};

export default Chat;
