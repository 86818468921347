import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import styles from './PropertyModal.module.scss';
import BtnProfile from '../../BtnProfile/BtnProfile';
import { useDispatch } from 'react-redux';
import { addTask } from '../../../../redux/slices/taskSlice/addTaskSlice';
import CustomDropdown from '../CustomDropdown/CustomDropdown';
import { useNavigate } from 'react-router-dom';
import CustomDropDownWork from '../CustomDropdown/CustomDropDownWork';
import CheckIcon from '@mui/icons-material/Check';
const PropertyModalContent = ({ onClose, isOpen, selectedProduct }) => {
  const [fordData, setFordData] = useState({
    address: '',
    comment: '',
    building_type: '',
    work_type: '',
    phone: '',
    type: selectedProduct.type,
    files: '',
  });
  const [files, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    // Очистка при размонтировании компонента
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFordData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  const shortenFileName = fileName => {
    const maxLength = 5; // Максимальная длина имени файла без расширения
    const extension = fileName.split('.').pop(); // Получаем расширение файла
    const nameWithoutExtension = fileName.slice(0, fileName.lastIndexOf('.')); // Обрезаем имя файла до расширения

    if (nameWithoutExtension.length > maxLength) {
      // Если имя файла слишком длинное, обрезаем и добавляем троеточие
      return nameWithoutExtension.slice(0, maxLength) + '...' + '.' + extension;
    }

    return fileName;
  };
  const handleChangeFile = e => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      setFile(file);
      setFileName(shortenFileName(file.name)); // Применяем сокращение
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('address', fordData.address);
    formData.append('comment', fordData.comment);
    formData.append('building_type', fordData.building_type);
    formData.append('work_type', fordData.work_type);
    formData.append('phone', fordData.phone);
    formData.append('type', selectedProduct.type);
    if (files) {
      formData.append('files', files); // Добавляем файл, если он выбран
    }

    dispatch(addTask(formData))
      .then(() => {
        navigate('confirmation');
      })
      .catch(error => {
        console.log(error);
      });
    setFordData({
      address: '',
      comment: '',
      building_type: '',
      work_type: null,
      phone: '',
    });
  };
  const workerCard = selectedProduct?.title === 'Aixland MasterCare';
  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div className={styles.modalContainer}>
        <div onClick={onClose} className={styles.backLink}>
          <CloseIcon />
        </div>
        <h2 className={styles.titleModal}>Application submission</h2>
        <form onSubmit={handleSubmit} className={styles.modalForm}>
          <input
            type="text"
            placeholder="Address"
            name="address"
            className={styles.modalInput}
            required
            value={fordData.address}
            onChange={handleChange}
          />
          <input
            className={styles.modalInput}
            type="text"
            placeholder="+49"
            name="phone"
            onChange={handleChange}
            value={fordData.phone}
          />

          <CustomDropdown
            value={fordData.building_type} // Текущее значение
            onChange={value =>
              setFordData({ ...fordData, building_type: value })
            }
          />
          {workerCard && (
            <>
              <CustomDropDownWork
                onChange={value =>
                  setFordData({ ...fordData, work_type: value })
                }
                value={fordData.work_type}
              />
            </>
          )}
          <textarea
            type="text"
            placeholder="Description of the problem"
            name="comment"
            value={fordData.comment}
            onChange={handleChange}
            required
            className={styles.modalInput}
          />

          {workerCard ? (
            <div
              className={`${styles.uploadContainer} ${fileName ? styles.fileNameTrue : ''}`}
            >
              <label htmlFor="fileUpload" className={styles.uploadLabel}>
                {files ? (
                  <div className={styles.uploadCircleCheckWork}>
                    <CheckIcon />
                  </div>
                ) : (
                  <div className={styles.uploadCircle}>+</div>
                )}
                {fileName ? (
                  <p className={styles.fileName}>{fileName}</p>
                ) : (
                  <p className={styles.uploadText}>Add photo or video</p>
                )}
              </label>
              <input
                type="file"
                id="fileUpload"
                name="url"
                className={styles.uploadInput}
                onChange={handleChangeFile}
              />
            </div>
          ) : (
            <div
              className={`${styles.uploadContainerFile} ${fileName ? styles.fileNameTrue : ''}`}
            >
              <label htmlFor="fileUpload" className={styles.uploadLabelFile}>
                {files ? (
                  <div className={styles.uploadCircleCheck}>
                    <CheckIcon />
                  </div>
                ) : (
                  <div className={styles.uploadCircleFile}>+</div>
                )}
                {fileName ? (
                  <p className={styles.uploadFileName}>{fileName}</p>
                ) : (
                  <p className={styles.uploadTextFile}>Upload a file</p>
                )}
              </label>
              <input
                type="file"
                id="fileUpload"
                name="url"
                className={styles.uploadInput}
                onChange={handleChangeFile}
              />
            </div>
          )}
          <div className={styles.wrapperBtnModal}>
            <BtnProfile marginTop="15px" title="NEXT" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default PropertyModalContent;
