import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { addMessage } from '../../../../redux/slices/chatSlice';
import Cookies from 'js-cookie';

import styles from './ModalDigitalTwin.module.scss';
import Chat from './Chat';

const ModalDigitalTwin = ({ closeModal, isOpen, ticketId }) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [messageHistory, setMessageHistory] = useState([]);
  const [socket, setSocket] = useState(null);
  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({
    message: '',
    file: '',
  });
  const profileUser = useSelector(state => state.profileSlice.user);
  useEffect(() => {
    if (isOpen) {
      const token = Cookies.get('token');
      // const wsUrl = `ws://127.0.0.1:8000/ws/chat/?ticketId=${ticketId}&authorization=Bearer ${token}`;
      const wsUrl = `wss://aixland.io/ws/chat/?ticketId=${ticketId}&authorization=Bearer ${token}`;
      const ws = new WebSocket(wsUrl);
      ws.onmessage = event => {
        console.log(event.data);

        if (event.data.startsWith('[')) {
          const validJsonData = JSON.parse(event.data.replace(/'/g, '"'));
          setMessageHistory(validJsonData);
        } else {
          setMessageHistory(prev => [...prev, JSON.parse(event.data)]);
        }
        if (event.data.startsWith('{') && event.data.endsWith('}')) {
          try {
            const data = JSON.parse(event.data); // Парсим JSON
            if (data.type === 'message') {
              dispatch(
                addMessage({
                  ticketId,
                  message: data.message,
                  userId: data.user_id,
                }),
              );
            }
          } catch (error) {
            console.error('❌ JSON parsing error:', error);
          }
        }
      };

      ws.onerror = error => {
        console.error('⚠️ Error WebSocket:', error);
      };
      setSocket(ws);
      return () => {
        ws.close();
      };
    }
  }, [ticketId, isOpen, dispatch]);
  const handleMessage = () => {
    if (socket && message.trim()) {
      const messageData = {
        type: 'send',
        message: message,
      };

      socket.send(JSON.stringify(messageData));
      setMessage('');
    }
  };

  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : '';

    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);
  const username = profileUser.username;
  return (
    <div onClick={handleOverlayClick} className={styles.modalOverlay}>
      <div className={styles.modalContainer}>
        <div className={styles.backLink}>
          <h1 className={styles.titleModal}>Message</h1>
          <CloseIcon onClick={closeModal} className={styles.closeIcon} />
        </div>
        <div className={styles.borderBottom}></div>

        <div className={styles.messagesContainer}>
          {messageHistory.length > 0 &&
            messageHistory?.map((msg, index) => (
              <div
                key={index}
                className={`${styles.containerMessage} ${
                  msg.username === username
                    ? styles.alignEnd
                    : styles.alignStart
                }`}
              >
                <div className={styles.containerSender}>
                  <div className={styles.avatarChronology}></div>
                  <div className={styles.userName}>{msg.username}</div>
                  <div className={styles.messageDate}>
                    <span className={styles.date}>{msg.timestamp.date} </span>/
                    <span className={styles.time}>{msg.timestamp.time}</span>
                  </div>
                </div>
                <p
                  className={`${styles.message} ${msg.username === username ? styles.you : styles.other}`}
                >
                  {msg.message}
                </p>
              </div>
            ))}
        </div>

        <div className={styles.containerFileAdd}>
          <div className={styles.fileAdd}>Add a file</div>
          <AddIcon className={styles.addIcon} />
        </div>
        <Chat
          handleMessage={handleMessage}
          setMessage={setMessage}
          message={message}
        />
      </div>
    </div>
  );
};

export default ModalDigitalTwin;
