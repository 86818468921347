import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthenticated, getProfile } from '../redux/slices/profileSlice';
import Cookies from 'js-cookie';

const ProtectedRoute = props => {
  const { loading, user } = useSelector(state => state.profileSlice);
  const dispatch = useDispatch();
  const token = Cookies.get('token');

  useEffect(() => {
    if (token && !user) {
      dispatch(getProfile()); // Используем вашу функцию
    }
  }, [token, dispatch, user]);
  // Todo вопрос почему мы задаем в массив зависимостей диспатч?

  // Если профиль загружается
  if (loading) {
    return <div>Loading...</div>;
  }
  if (loading) {
    return <div>Loading...</div>;
  }

  return token ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
