import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './FinishedWorks.module.scss';
import Tasks from '../../../../constants/Tasks/Tasks';
import ModalDashBoard from '../../../../Components/DachBoard/modalWindow/ModalDashBoard';
import ModalFinishedWorks from './ModalFinishedWorks/ModalFinishedWorks';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getTask } from '../../../../redux/slices/taskWorkerSLice';
const statuses = ['At work', 'Waiting', 'Completed'];
const FinishedWorks = () => {
  const tasksStore = useSelector(
    state => state.taskReducers.getTaskSlice.tasks,
  );
  const user = useSelector(state => state.profileSlice.user);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const isEngineer = user?.role !== null;
  const { taskId } = useParams();
  useEffect(() => {
    dispatch(getTask({ ticketId: taskId }));
  }, [taskId]);

  // Todo сделать useCallback
  const onOpen = useCallback(
    taskId => {
      setSelectedTask(taskId);
      setIsOpen(isEngineer);
    },
    [isEngineer],
  );

  // Todo сделать useCallback
  const onClose = () => {
    setIsOpen(false);
    setSelectedTask(null);
  };

  const groupedTasks = useMemo(() => {
    return statuses.reduce((acc, status) => {
      acc[status] = tasksStore.filter(
        task =>
          task.status_for_worker === status &&
          (!taskId || task.ticket_id === taskId),
      );
      return acc;
    }, {});
  }, [tasksStore, taskId]);

  const data = Object.entries(groupedTasks);
  return (
    <div className={styles.containerFinished}>
      <h1 className={styles.title}>Creating a solar panel</h1>
      <div className={styles.div}>
        <div className={styles.cardContainer}>
          {data.map(([key, value]) => (
            <div key={key} className={styles.column}>
              <h2 className={styles.columnTitle}>{key}</h2>
              {value.length > 0
                ? // Если taskId существует, отображаем только выбранную задачу
                  value
                    .filter(task => !taskId || task.ticket_id === taskId)
                    .map(task => (
                      <Tasks
                        tasksStore={task}
                        key={task.id}
                        margin="10px"
                        minHeight="220px"
                        task={task}
                        width="100%"
                        onOpen={onOpen}
                      />
                    ))
                : ''}
            </div>
          ))}
        </div>
      </div>
      <ModalDashBoard isOpen={isOpen}>
        <ModalFinishedWorks taskId={taskId} onClose={onClose} />
      </ModalDashBoard>
    </div>
  );
};

export default FinishedWorks;
