import { useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import { getTasks } from '../../../redux/slices/taskSlice/getTaskSlice';
import { Route, Routes } from 'react-router-dom';
import Board from '../../../pages/DashBoardPage/WorkerPage/Board/Board';
import FinishedWorks from '../../../pages/DashBoardPage/WorkerPage/FinishedWorks/FinishedWorks';

const MainBoard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTasks());
  }, [dispatch]);
  return (
    <Routes>
      <Route path="/" element={<Board />} />
      <Route path="/finishedWorks/:taskId" element={<FinishedWorks />} />
    </Routes>
  );
};

export default MainBoard;
