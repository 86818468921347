import { configureStore } from '@reduxjs/toolkit';
import formSlice from './slices/formSlice';
import applicationSlice from './slices/applicationSlice';
// import taskSlice from './slices/taskSlice';
import profileSlice from './slices/profileSlice';
import usersSlice from './slices/usersSlice';
import commentSlice from './slices/commentSlice';
import workersSlice from './slices/workerSlice';
import taskWorkerSLice from './slices/taskWorkerSLice';
import taskReducers from './slices/taskSlice/index';
import chatSlice from './slices/chatSlice';
export const store = configureStore({
  reducer: {
    formSlice,
    applicationSlice,
    taskReducers,
    chatSlice,
    profileSlice,
    usersSlice,
    commentSlice,
    workersSlice,
    taskWorkerSLice,
  },
});
